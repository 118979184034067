"use client";

import { useTranslations } from "next-intl";
import Link from "next/link";
import { UserAuthForm } from "./_components/user-auth-form";
import ImgBackground from "../../../../public/bg-login.jpg";
import LogoImg from "../../../../public/logo.png";
import Image from "next/image";

export default function Auth() {
  const t = useTranslations("pages.auth");
  const termsMessageArr = t("termsMessage").split("#[]");

  return (
    <div className="flex flex-col-reverse md:flex-row h-screen bg-gray-200">
      <div className="flex flex-1 justify-center items-center  xl:py-3">
        <div className="flex flex-col lg:space-y-6 lg:w-[562px] lg:rounded bg-white px-10 lg:px-[70px] py-16 h-full max-h-[664px]">
          <div className="flex flex-col items-center justify-center gap-7">
            <Image src={LogoImg} alt="Logo Nosso Mandado" width={112} height={113}/>
            <h1 className="text-2xl mb-6 font-semibold tracking-tight text-gray-700">
              {t("title")}
            </h1>
          </div>
          <UserAuthForm />
          <p className="px-8 text-center text-sm text-muted-foreground">
            {termsMessageArr[0]}
            <Link
              href="/terms"
              className="underline underline-offset-4 hover:text-primary"
            >
              {t("termsOfService")}
            </Link>
            {termsMessageArr[1]}
            <Link
              href="/privacy"
              className="underline underline-offset-4 hover:text-primary"
            >
              {t("privacyPolicy")}
            </Link>
            {termsMessageArr[2]}
          </p>
        </div>
      </div>
      <div className="h-5 w-full lg:block md:h-full lg:w-1/2 md:w-[40%] md:mx-auto relative">
        <Image
          src={ImgBackground}
          alt="background"
          fill
        />
      </div>
    </div>
  );
}
