"use client";

import GoogleIcon from "@/../public/icons/google.svg";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useForm } from "react-hook-form";
import { emailLoginAction, socialLoginAction } from "../actions";

export function UserAuthForm() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const t = useTranslations("pages.auth");

  const onSubmit = handleSubmit(async (data) => {
    try {
      await emailLoginAction(data.email);
      toast({
        title: t("toasts.mailSuccess.title"),
        description: t("toasts.mailSuccess.description"),
      });
    } catch (error) {
      toast({
        title: t("toasts.mailError.title"),
        description: t("toasts.mailError.description"),
      });
    }
  });

  return (
    <div className="relative">
      {isSubmitting && (
        <Loader2
          className={cn(
            "absolute m-auto left-0 right-0 h-24 w-24 animate-spin"
          )}
        />
      )}
      <div className={cn("grid gap-6")}>
        <form onSubmit={onSubmit}>
          <div className="grid gap-2">
            <div className="grid gap-1">
              <Label className="sr-only" htmlFor="email">
                {t("form.email.name")}
              </Label>
              <Input
                id="email"
                className="h-[45px]"
                placeholder={t("form.email.placeholder")}
                type="email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                {...register("email")}
                disabled={isSubmitting}
              />
            </div>
            <Button type="submit" disabled={isSubmitting}>
              {t("form.submitBtn")}
            </Button>
          </div>
        </form>
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-background px-2 text-muted-foreground">
              {t("form.orSocialTitle")}
            </span>
          </div>
        </div>
        <Button
          variant="outline"
          type="button"
          onClick={() => socialLoginAction("google")}
          disabled={isSubmitting}
        >
          <Image alt="Google" width={20} height={20} src={GoogleIcon} />
          <div className="ml-2">Google</div>
        </Button>
      </div>
    </div>
  );
}
